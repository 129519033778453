<template>
  <!-- https://ithelp.ithome.com.tw/articles/10223518 -->
  <!-- $emit used when child element want to pass some data to parent element-->
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img :src="require('@/assets/images/logos/ttn_logo2.png')" width="100"></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5" v-for="link in nav_link" :key="link.title_zh">
      <nav-menu-link v-if="!link.isGroup" :title="link.title_zh" :to="link.name" :id="link.id" class="nav-menu-link"></nav-menu-link>
      <nav-menu-group v-else :title="link.title_zh">
        <nav-menu-link
          v-for="sub_group in link.subGroup"
          :key="sub_group.title_zh"
          :title="sub_group.title_zh"
          :to="sub_group.name"
          :id="link.id"
          class="nav-menu-link"
        ></nav-menu-link>
      </nav-menu-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    nav_link: [
      {
        isGroup: true,
        title_zh: 'Membership',
        name: { name: 'membership' },
        subGroup: [
          {
            title_zh: 'Member',
            name: { name: 'member-management' },
          },
          {
            title_zh: 'Performer',
            name: { name: 'performer-management' },
          },
          {
            title_zh: 'Vendor',
            name: { name: 'vendor-management' },
          },
        ],
      },
      {
        isGroup: false,
        title_zh: 'Quotation',
        name: { name: 'quotation-management' },
      },
      {
        isGroup: false,
        title_zh: 'Event',
        name: { name: 'event-management' },
      },
      {
        isGroup: true,
        title_zh: 'Store',
        name: { name: 'store' },
        subGroup: [
          {
            title_zh: 'Product',
            name: { name: 'product-management' },
          },
        ],
      },
      {
        isGroup: false,
        title_zh: 'Redeem',
        name: { name: 'redeem-management' },
      },
      {
        isGroup: false,
        title_zh: 'Order',
        name: { name: 'order-management' },
      },
        {
        isGroup: false,
        title_zh: 'Enquiry',
        name: { name: 'enquiry-management' },
      },
      {
        isGroup: false,
        title_zh: 'Voting',
        name: { name: 'voting-management' },
      },      
    ],
  }),
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  background: #000 !important;
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.nav-menu-link:not(.v-list-item--active):not(.v-list-item--disabled){
  color:#DDD !important;
  --color:#DDD !important;
}
</style>
