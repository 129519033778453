import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login'
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      requireAuth: false
    }
  },
  {
    path: '/member-management',
    name: 'member-management',
    component: () => import('@/views/pages/member-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/member-management/detail/:id',
    name: 'member-management-detail',
    component: () => import('@/views/pages/member-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/vendor-management',
    name: 'vendor-management',
    component: () => import('@/views/pages/vendor-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/vendor-management/detail/:id',
    name: 'vendor-management-detail',
    component: () => import('@/views/pages/vendor-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/performer-management',
    name: 'performer-management',
    component: () => import('@/views/pages/performer-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/performer-management/add',
    name: 'add-performer',
    component: () => import('@/views/pages/performer-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/performer-management/detail/:id',
    name: 'performer-management-detail',
    component: () => import('@/views/pages/performer-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  }, 
  {
    path: '/event-management',
    name: 'event-management',
    component: () => import('@/views/pages/event-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/event-management/add',
    name: 'add-event',
    component: () => import('@/views/pages/event-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/event-management/detail/:id',
    name: 'event-management-detail',
    component: () => import('@/views/pages/event-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/quotation-management',
    name: 'quotation-management',
    component: () => import('@/views/pages/quotation-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/quotation-management/detail/:id',
    name: 'quotation-management-detail',
    component: () => import('@/views/pages/quotation-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/product-management',
    name: 'product-management',
    component: () => import('@/views/pages/product-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/product-management/add',
    name: 'add-product',
    component: () => import('@/views/pages/product-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/product-management/detail/:id',
    name: 'product-management-detail',
    component: () => import('@/views/pages/product-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/order-management',
    name: 'order-management',
    component: () => import('@/views/pages/order-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/order-management/detail/:id',
    name: 'order-management-detail',
    component: () => import('@/views/pages/order-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/redeem-management',
    name: 'redeem-management',
    component: () => import('@/views/pages/redeem-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/redeem-management/detail/:id',
    name: 'redeem-management-detail',
    component: () => import('@/views/pages/redeem-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/enquiry-management',
    name: 'enquiry-management',
    component: () => import('@/views/pages/enquiry-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/enquiry-management/detail/:id',
    name: 'enquiry-management-detail',
    component: () => import('@/views/pages/enquiry-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/voting-management',
    name: 'voting-management',
    component: () => import('@/views/pages/voting-management.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/voting-management/add',
    name: 'add-voting',
    component: () => import('@/views/pages/voting-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: '/voting-management/detail/:id',
    name: 'voting-management-detail',
    component: () => import('@/views/pages/voting-management-detail.vue'),
    meta: {
      layout: 'content',
      requireAuth: true,
    },
  },
  {
    path: "*",
    redirect: 'error-404'
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  }
]

// const router = new VueRouter({ mode: 'history', base: process.env.BASE_URL, routes })

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     if (router.app.$store.state.api.login_status && router.app.$store.state.api.login_data!='' && router.app.$store.state.api.login_data!=null) {
//       next() // 往下執行
//     } else {
//       //如果使用者token無效則跳轉到login頁面
//       if (to.path !== '/login') {
//         next('/login')
//       } else {
//         next() // 往下執行
//       }
//     }
//   } else if (!to.meta.requireAuth && router.app.$store.state.api.login_status && router.app.$store.state.api.login_data!='' && router.app.$store.state.api.login_data!=null) {
//     next('/member-management')
//   } else {
//     next() // 往下執行
//   }
// })

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (
      router.app.$store.state.api.login_status &&
      router.app.$store.state.api.login_data != '' &&
      router.app.$store.state.api.login_data != null &&
      router.app.$store.state.api.login_data != undefined
    ) {

      next() // 往下執行
    } else {
      //如果使用者token無效則跳轉到login頁面
      if (to.path !== '/login') {
        next('/login')
      } else {
        next() // 往下執行
      }
    }
  } else if (
    router.app.$store.state.api.login_status &&
    router.app.$store.state.api.login_data != '' &&
    router.app.$store.state.api.login_data != null
  ) {
    next('/member-management')
  } else {
    next() // 往下執行
  }
})

export default router
