import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'

Vue.config.productionTip = false

Vue.filter('format_date', function(value) {
  // to YYYY-MM-DD format
  if (!value) return ''
  return value.split('T')[0]
})

Vue.filter('format_dateTime', function(value) {
  // to YYYY-MM-DD hh:mm:ss format
  if (!value) return ''
  let date = value.substring(0, 10)
  let time = value.substring(11, 16)
  return date + ' ' + time
})

Vue.prototype.XHR = new function () {
  this.request = function (type, url, contentType, dataHeader, dataObj, fnSuccess, fnFail,
    fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync) {
    // Requester.addRequest()

    const dataName = url
    let data = null
    const request = new XMLHttpRequest()
    request.open(type, url, isAsync !== false)

    if (isAsync !== false) {
      request.timeout = 60 * 1000 // 1 min
    }

    if (dataHeader) {
      for (const e in dataHeader) {
        request.setRequestHeader(e, dataHeader[e])
      }
    }

    if (dataObj) {
      data = new FormData()
      for (let e in dataObj) {
        data.append(e, dataObj[e])
      }
      // if (type == 'POST') {
      // 	if (dqa('meta[name="xsrf-token"]').length) {
      // 		const token = dq('meta[name="xsrf-token"]').content;
      // 		if (token != '') {
      // 			data.append('token', JSON.stringify(token))
      // 		}
      // 		// request.withCredentials = true;
      // 	}
      // }
    }

    function jsonCallback() {
      let error, response;

      function temp() {
        try {
          return JSON.parse(request.responseText)
        } catch (e) {
          error = e
          return false;
        }
      };
      response = temp()
      if (!response) {
        console.warn(type + ' XHR: Request Failed (JSON Parse Exception) - [Key: ' + dataName +
          ']')
        console.warn(request.responseText)
        if (fnFail) fnFail('JSON Parse Exception', error)
      } else {
        checkEmptyCallback(response)
      }
    }

    function checkEmptyCallback(response) {

      if ((allowEmptyResponse && (response == null || response == '')) ||
        (!allowEmptyResponse && response != null && response != '')) {
        console.log(type + ' XHR: Request Succeed - [Key: ' + dataName + ']')
        console.log(response)
        if (fnSuccess) fnSuccess(response)
      } else {
        console.warn(type + ' XHR: Request Failed (Invalid Response) - [Key: ' + dataName + ']')
        console.log(response)
        if (fnFail) fnFail(response)
      }
    }


    request.onload = function () {
      if (request.readyState === 4) {
        if (request.status === 200) {
          if (contentType == 'json') {
            jsonCallback()
          } else {
            checkEmptyCallback(request.responseText)
          }
        } else {
          console.warn(type + ' XHR: Status Error (status: ' + request.status + ') - [Key: ' +
            dataName + ']')
          if (fnStatusError) fnStatusError(request.status)
        }
      } else {
        console.warn(type + ' XHR: Status Error (readyState: ' + request.readyState +
          ') - [Key: ' + dataName + ']')
        if (fnStatusError) fnStatusError(request.readyState)
      }
      // Requester.finishRequest()
    }

    let progress = 0;

    function progressCallback(ev) {
      if (ev.lengthComputable) {
        let percent = ev.loaded / ev.total * 100
        progress = Math.max(progress, percent)
        if (fnRequestProgress) fnRequestProgress(progress, ev.loaded, ev.total)
      } else {
        if (fnRequestProgress) fnRequestProgress(progress, ev.loaded, null)
      }
    }

    if (dataObj) {
      request.upload.onprogress = function (ev) {
        progressCallback(ev)
      }
    } else {
      request.onprogress = function (ev) {
        progressCallback(ev)
      }
    }

    request.onabort = function () {
      // Requester.finishRequest()
      console.warn(type + ' XHR: Connection Failed (Abort) - [Key: ' + dataName + ']')
      if (fnRequestError) fnRequestError('Abort')
    };
    request.onerror = function () {
      // Requester.finishRequest()
      console.warn(type + ' XHR: Connection Failed (Error) - [Key: ' + dataName + ']')
      if (fnRequestError) fnRequestError('Error')
    };
    request.ontimeout = function () {
      // Requester.finishRequest()
      console.warn(type + ' XHR: Connection Failed (Timeout) - [Key: ' + dataName + ']')
      if (fnRequestError) fnRequestError('Timeout')
    };

    dataObj ? request.send(data) : request.send()
  };
  this.post = function (url, contentType, dataHeader, dataObj, fnSuccess, fnFail, fnRequestError,
    fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync) {
    this.request('POST', url, contentType, dataHeader, dataObj, fnSuccess, fnFail,
      fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync)
    // try {
    // 	this.request('POST', url, contentType, dataHeader, dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync)
    // } catch(err) {
    // 	console.warn('POST XHR: Connection Failed (Exception) - [Key: ' + url + ']')
    // 	if (fnRequestError) fnRequestError()
    // }
  };
  this.api = function (dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError,
    fnRequestProgress, isAsync) {

    /* let key = Object.keys(dataObj)[0]
    if (key != 'cms_admin_login') {
      // console.log(dataObj[key])
      if (dataObj && Object.keys(dataObj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype) {
        let token = {
          token: store.state.token
        }
        dataObj[key] = token
        console.log(dataObj)
      } else {
        // console.log('key is not undefined')
        let obj = JSON.parse(dataObj[key])
        obj.token = store.state.token

        dataObj[key] = JSON.stringify(obj)
        // console.log(dataObj[key].token = store.state.token)
      }
    } */

    if (arguments.length < 7) {
      if (arguments.length < 6) {
        if (arguments.length < 5) {
          if (arguments.length < 4) {
            if (arguments.length < 3) {
              fnFail = null;
            }
            fnRequestError = fnFail;
          }
          fnStatusError = fnFail;
        }
        fnRequestProgress = null;
      }
      isAsync = true;
    }

    function fnApiSuccess(response) {
      if (response['result'] !== undefined && response.result == 'success') {
        if (response['token'] !== undefined) {
          fnSuccess(response.result, response.data, response.token)
        } else {
          fnSuccess(response.result, response.data)
        }
      } else if (fnFail) {
        fnFail(response.result, response.data)
      }
    }

    function fnApiFail(response) {
      if (fnFail) {
        if (response['result'] !== undefined) {
          fnFail(response.result, response.data)
        } else {
          fnFail('', response)
        }
      }
    }
    this.post(
      'https://thistownneeds.online/Backend/request.php',
      // 'https://www.innpression.com/api/test/unifo',
      'json', {}, // {'Content-type': 'application/json; charset=utf-8'}, 
      dataObj, fnApiSuccess, fnApiFail, fnRequestError, fnStatusError, fnRequestProgress,
      false, // allowEmptyResponse
      isAsync !== false
    )
  };
  this.time = function (dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError,
    fnRequestProgress, isAsync) {
    if (arguments.length < 7) {
      if (arguments.length < 6) {
        if (arguments.length < 5) {
          if (arguments.length < 4) {
            if (arguments.length < 3) {
              fnFail = null;
            }
            fnRequestError = fnFail;
          }
          fnStatusError = fnFail;
        }
        fnRequestProgress = null;
      }
      isAsync = true;
    }

    function fnApiSuccess(response) {

      fnSuccess(response)

    }

    function fnApiFail(response) {
      if (fnFail) {
        if (response['result'] !== undefined) {
          fnFail(response.result, response.data)
        } else {
          fnFail('', response)
        }
      }
    }
    this.post(
      window.location.hostname.indexOf('football.test') < 0 ? '/backend/request.php' :
      'https://www.40thanniversary-aji-no-chinmi.com/backend/request.php',
      '', {}, // {'Content-type': 'application/json; charset=utf-8'}, 
      dataObj, fnApiSuccess, fnApiFail, fnRequestError, fnStatusError, fnRequestProgress,
      false, // allowEmptyResponse
      isAsync !== false
    )
  };
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
